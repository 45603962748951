.about_us--container {
    width: 80%;
    margin: 50px auto;
}

.about_us--title {
    border-bottom: 2px solid #c7c7c7;
}

.about_us--title span {
    font-size: 40px;
    letter-spacing: 2px;
    /* color: #fff; */
}

.aboutUs--trainers {
    margin: 50px auto;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutUs--trainers p {
    font-size: 20px;
    font-weight: 500;
    color: #252525;
}

.aboutUs--trainers a {
    display: block;
    text-decoration: none;
    color: #fff;
    padding: 10px 15px;
    background: #f25d42;
    border-radius: 50px;
    cursor: pointer;
    width: 120px;
    margin-top: 20px;
    font-size: 18px;
    margin-left: 10px;
}