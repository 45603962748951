.footer--container {
    text-align: center;
    margin: 100px 50px;
    /* color: #fff; */
}

.footer--title span {
    /* color: #606060; */
    font-size: 30px;
}

.footer--media {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.footer--media img {
    width: 30px;
    cursor: pointer;
}

.footer--media img:hover {
    scale: 1.5;
}

.footer--content {
    margin-top: 20px;
}

.footer--content span a {
    color: #252525;
}