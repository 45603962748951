.course_container--course-card {
    width: 60%;
    margin: auto;
    display: flex;
    gap: 20px;
    border: 1px solid #c7c7c7;
    /* background: rgba(0, 0, 0, 0.4); */
    background: transparent;
    backdrop-filter: blur(3px);
    /* color: #fff; */
    border: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin-bottom: 30px;
    transition: all 1s;
    position: relative;
    border-radius: 3px;
}

.course-card--image {
    width: 30%;
    height: 300px;
    display: grid;
    place-items: center;
}

.course-card--image img {
    width: 60%;
}

.course-card--details {
    width: 60%;
    padding: 10px;
}

.course-card--details span {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 2px;
}

.course-card--features {
    margin-left: 20px;
    margin-top: 30px;
}

.course-card--features span {
    display: block;
    margin-bottom: 10px;
    font-size: 1rem;
}

.course-card--features li {
    font-size: 0.8rem;
}

.course--move-right {
    margin-right: -100%;
}

.course--move-right-normal {
    margin-right: 20%;
}

.course--move-left {
    margin-left: -100%;
}

.course--move-left-normal {
    margin-left: 20%;
}

.course_container--course-card a {
    text-decoration: none;
    color: #fff;
    padding: 10px 15px;
    background: #f25d42;
    position: absolute;
    bottom: 10px;
    right: 10px;
    border-radius: 50px;
    user-select: none;
    cursor: pointer;
}

.course_container--course-card a:hover {
    scale: 1.1;
}

.course_container--course-card a:active {
    scale: 0.9;
}

@media (max-width:768px) {
    .course_container--course-card {
        width: 90%;
        display: block;
    }

    .course-card--image {
        width: 100%;
        height: 200px;
    }

    .course-card--image img {
        width: 40%;
    }

    .course-card--details {
        width: 90%;
        padding-left: 15px;
    }

    .course-card--details p {
        font-size: 12px;
    }

    .course--move-right-normal {
        margin-right: 5%;
    }

    .course--move-left-normal {
        margin-left: 5%;
    }

    .course_container--course-card a {
        display: none;
    }
}