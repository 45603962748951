@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap');

.app--container {
    width: 100%;
    /* background: #121212; */
    font-family: 'Open Sans', sans-serif;
    /* background: #fcf8f6; */
    background-image: url('./Assets/Sprinkle.svg');
}

.app--container::-webkit-scrollbar {
    width: 5px;
}

.app--container::-webkit-scrollbar-thumb {
    background: #969696;
}

.app--container::-webkit-scrollbar-track {
    background: #c7c7c7;
}